// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`);
//   }
// };

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Byla zjištěná nová verze aplikace. Obnovte ji prosím pro aktualizaci.`,
  );

  if (answer === true) {
    window.location.reload();
  }
};
